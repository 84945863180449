import React from "react";
import { Typography, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AppChrome } from "./AppChrome";

export function PrivacyPolicy() {
  return (
    <AppChrome routingPath={[]}>
      <Container maxWidth="md">
        <Typography variant="h2">Ochrana osobních údajů</Typography>
        <p>
          Děkuji za Vaši návštěvu na mých stránkách, těší mě Váš zájem. Beru
          ochranu vašich soukromých údajů vážně a chci, abyste se při návštěvě
          mých internetových stránek cítili dobře. Ochrana vašeho soukromí při
          zpracování osobních údajů je pro mě důležitou záležitostí, kterou při
          mých obchodních procesech beru na zřetel.
        </p>
        <p>
          Osobní údaje, které jsou získávány během návštěvy těchto webových
          stránek, zpracováváme v souladu se Zákonem č. 101/2000 Sb. o ochraně
          osobních údajů.
        </p>
        <Typography variant="h5">Právo na informace</Typography>
        <p>
          Na požádání vám Martina Kolmanová podle možnosti obratem a písemně
          oznámí, zda a jaké osobní údaje má o vás zaznamenané. Pokud by
          navzdory našim snahám o správnost údajů a aktuálnost byly zaznamenány
          nesprávné informace, na požádání je opravíme.
        </p>
        <p>
          Pokud máte otázky týkající se zpracování vašich osobních údajů, můžete
          je směřovat na logopediesusmevem@gmail.cz, kde vám jsme k dispozici
          nejen v případě žádosti o informace, ale i v případě podnětů nebo
          stížností.
        </p>
        <Typography variant="h5">Kodex ochrany údajů</Typography>
        <Typography variant="h5">
          Získávání a zpracovávání osobních údajů
        </Typography>
        <p>
          Když navštívíte naše webové stránky, zaznamenají naše webové servery
          standardním způsobem IP adresu, která vám byla přidělená
          poskytovatelem internetových služeb, webovou stránku, z které nás
          navštívíte, webové stránky, které u nás navštívíte, a také datum a
          délku návštěvy. Osobní údaje se zaznamenají jen tehdy, když nám je
          poskytnete z vlastní vůle, například v rámci registrace, anket,
          vypsání cen nebo na realizaci smlouvy.
        </p>
        <Typography variant="h5">Bezpečnost</Typography>
        <p>
          Martina Kolmanová přijímá technická, organizační a bezpečnostní
          opatření, aby chránil u nás vedené údaje proti manipulaci, ztrátě,
          zničení a zásahu nepovolaných osob. Naše bezpečnostní opatření se s
          vývojem technologií neustále zlepšují.
        </p>
        <Typography variant="h5">
          Použití a postoupení osobních údajů
        </Typography>
        <p>
          Martina Kolmanová používá vaše osobní údaje na účely technické správy
          webových stránek, zákaznickou administrativu, na ankety týkající se
          produktů a na marketingové účely vždy jen v takovém rozsahu, jaký je k
          tomu potřebný.
        </p>
        <p>
          Postoupení osobních údajů státním zařízením a úřadům následuje jen v
          rámci závazných právních předpisů. Naši spolupracovníci, agentury a
          obchodníci jsou z naší strany vázáni diskrétností.
        </p>
        <Typography variant="h5">Možnost odhlášení</Typography>
        <p>
          Chceme vaše údaje použít k tornu, abychom vás mohli informovat o
          našich produktech a službách, případně zjistit váš názor na ně. Účast
          na takovýchto akcích je samozřejmě dobrovolná. Pokud byste s nimi
          nesouhlasili, můžete nám to kdykoliv oznámit, abychom mohli údaje
          podle toho zablokovat. V případě emailové komunikace se z odběru
          můžete kdykoliv odhlásit s pomocí odhlašovacího odkazu uvedeného v
          patičce každého emailu.
        </p>
        <Typography variant="h5">Cookies</Typography>
        <p>
          Martina Kolmanová používá cookies k tomu, aby mohl sledovat preference
          návštěvníků a aby mohl podle toho optimálně vytvářet webové stránky.
          Cookies jsou malé “soubory”, které se uloží na váš pevný disk. To vede
          k usnadnění navigace a zajištění vysoké míry uživatelského komfortu
          webové stránky. Cookies se mohou využívat ke zjištění, zda jste už ze
          svého počítače navštívili naše stránky. Identifikuje se pouze cookie
          na vašem počítači. Používání cookies můžete deaktivovat ve vašem
          internetovém prohlížeči.
        </p>
        <Typography variant="h5">
          Souhlas se zpracováním osobních údajů
        </Typography>
        <p>
          Prohlášení prodávajícího: Martina Kolmanová se zavazuje plně
          respektovat důvěrný charakter Vašich osobních a firemních dat, která
          jsou zabezpečena proti neautorizovanému přístupu a chráněna proti
          zneužití. Údaje, které zadáváte v objednávce eBooku Jak úspěšně
          prodávat eBook jsou nezbytné pro Vaši identifikaci jako kupujícího.
          Používáme je k realizaci celého obchodu, včetně nezbytných účetních
          operací, vystavení daňových dokladů, identifikaci Vašich
          bezhotovostních plateb a pro komunikaci s Vámi.
        </p>
        <p>
          Vaše detailní osobní data i údaje o nákupech jsou ukládány v databázi
          s přísným zabezpečením proti zneužití a nejsou poskytovány třetím
          stranám.
        </p>
        <p>
          Souhlas registrujícího se návštěvníka webu: Vyplněním webového
          formuláře souhlasí kupující se zařazením všech jím vyplněných osobních
          údajů do databáze Martina Kolmanová, Karolíny Světlé 636, 50002 Hradec
          Králové, 50002 , IČO 05327679, jakožto správce, a s jejich následným
          zpracováním prostřednictvím zpracovatele pro marketingové účely a
          obchodní sdělení prostřednictvím elektronických prostředků dle zákona
          č. 480/2004 Sb., a to na dobu do odvolání souhlasu.
        </p>
        <p>
          Zároveň registrovaný uděluje souhlas s tím, aby mu prodávající zasílal
          informace o připravovaných akcích a nabídce svých obchodních partnerů.
        </p>
        <Typography variant="h5">Právo na smazání údajů</Typography>
        <p>
          Pokud budete chtít smazat váš uživatelský účet a odstranit všechna
          osobní data z aplikace, kontaktujte nás na emailu
          logopediesusmevem@gmail.cz. Vaše data budou bez odkladu smazána,
          nejdéle do 30 dnů.
        </p>
        <p>
          <Link to="/">Zpět na hlavní stránku</Link>
        </p>
      </Container>
    </AppChrome>
  );
}
