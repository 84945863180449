const FIREBASE_CONFIG = {
  apiKey: "AIzaSyB22xX5TKa0zgdHEqnPc-Bw4aK28vGz5ZY",
  authDomain: "logotron.cz",
  databaseURL: "https://logotron-cz.firebaseio.com",
  projectId: "logotron-cz",
  storageBucket: "logotron-cz.appspot.com",
  messagingSenderId: "1053337360331",
  appId: "1:1053337360331:web:ff0566409f2bc84db0c84e",
  measurementId: "G-GG9KYSXR2P",
};

export default FIREBASE_CONFIG;
