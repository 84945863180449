import firebase from "firebase/app";
import { useUser } from "../auth/UserContext";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  List,
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    width: 300,
    padding: 24,
  },
  displayName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export function UserMenu() {
  const classes = useStyles();
  const { user, profile } = useUser();
  if (!user) {
    return null;
  }
  const name = profile?.displayName || user.displayName || user.email || "User";
  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.displayName}>
        {name}
      </Typography>
      <List>
        <Divider />
          <ListItem button component={RouterLink} to="/app/profil">
          <ListItemText primary="Můj profil" />
        </ListItem>
        <ListItem button onClick={() => firebase.auth().signOut()}>
          <ListItemText primary="Odhlásit se" />
        </ListItem>
      </List>
    </div>
  );
}
