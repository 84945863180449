import React, { useState } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import {
  CreateSubscriptionActions,
  UnknownObject,
} from "@paypal/paypal-js/types/components/buttons";
import { AppChrome } from "./AppChrome";
import {
  Container,
  CircularProgress,
  Grid,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { useUser } from "auth/UserContext";
import { PLANS } from "@logotron/shared";
import { useHistory } from "react-router-dom";
import { Plan, Plans } from "./Plans";
import firebase from "firebase/app";

const useStyles = makeStyles((theme) => ({
  title: { margin: `${theme.spacing(2)}px 0` },
  buy: {
    "& > div": {
      padding: theme.spacing(2),
      alignItems: "center",
      textAlign: "center",
    },
  },
}));

const pp = {
  onError: function (err: any) {
    console.log("error", err);
  },
};

function BuyPage() {
  const classes = useStyles();
  const history = useHistory();
  const [plan, setPlan] = useState<Plan | null>(null);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const [consent, setConsent] = useState(false);
  const [{ isPending }] = usePayPalScriptReducer();
  const { user, userDocRef, profile } = useUser();
  const loading = !userDocRef || !profile || !user; // this is in protected path

  return (
    <AppChrome routingPath={[]}>
      <Container maxWidth="md">
        <Typography variant="h2" className={classes.title}>
          Předplatné
        </Typography>
        <Plans value={plan} onChange={setPlan} />
        {plan && (
          <Grid item xs={12} className={classes.buy}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="consent"
                    value={consent}
                    onChange={(ev) => setConsent(ev.target.checked)}
                  />
                }
                label="Souhlasím se Všeobecnými obchodními podmínkami"
                labelPlacement="start"
              />
            </FormGroup>
            {loading || isPending ? (
              <CircularProgress color="inherit" />
            ) : (
              <PayPalButtons
                disabled={!consent}
                style={{ layout: "horizontal" }}
                createSubscription={(
                  _data: UnknownObject,
                  actions: CreateSubscriptionActions
                ) =>
                  actions.subscription
                    .create({
                      plan_id: PLANS[plan].paypalId,
                      custom_id: user!.uid,
                    })
                    .then((value) => {
                      console.log(value);
                      setSubscriptionId(value);
                      return value;
                    })
                }
                onApprove={() => {
                  firebase.analytics().logEvent("purchase", {
                    transaction_id: subscriptionId || "unknown-transaction-id",
                    value: PLANS[plan].value,
                    currency: "CZK",
                    subscription_plan_type: PLANS[plan].name,
                  });
                  history.push("/app/profil?pending");
                  return Promise.resolve();
                }}
                onError={pp.onError}
              />
            )}
          </Grid>
        )}
      </Container>
    </AppChrome>
  );
}

export const Buy = () => (
  <PayPalScriptProvider
    options={{
      currency: "CZK",
      vault: true,
      intent: "subscription",
      "client-id":
        "AQlm6P7YV40cy_E0MTE2NibETM2E8qEc3XyeNxdUKn7fPLwoK6ElsyCEQWx3ZUj21qwL18YJUkzJd66n",
    }}
  >
    <BuyPage />
  </PayPalScriptProvider>
);
