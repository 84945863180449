import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppChrome } from "./AppChrome";
import {
  Container,
  CircularProgress,
  makeStyles,
  Typography,
  Button,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogActions,
  Backdrop,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useUser } from "auth/UserContext";
import { cancelSubscription } from "rest/user";
import { SubscriptionExpirationDate } from "controls/SubscriptionExpirationDate";

const useStyles = makeStyles((theme) => ({
  title: { margin: `${theme.spacing(2)}px 0` },
  buy: {
    "& > div": {
      padding: theme.spacing(2),
      alignItems: "center",
      textAlign: "center",
    },
  },
  field: { margin: `${theme.spacing(2)}px 0` },
}));

type CancelState =
  | "None"
  | "Confirmation"
  | "InProgress"
  | "Success"
  | "Failure";

export function CancelSubscriptionPage() {
  const classes = useStyles();
  const [uiState, setUiState] = useState<CancelState>("None");
  const { user, subscription, userDocRef, profile } = useUser();
  const loading = !userDocRef || !profile || !user; // this is in protected path

  return (
    <AppChrome routingPath={[]}>
      <Container maxWidth="md">
        <Typography variant="h2" className={classes.title}>
          Ukončit předplatné
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box className={classes.field}>
              <Typography variant="body1">
                Svoje předplatné můžete kdykoliv zrušit. Přístup do aplikace Vám
                zůstane po celý zbytek doby, kterou již máte zaplacenou.
              </Typography>
            </Box>
            {!subscription || subscription === "loading" ? ( // this page is for subscribers only
              <CircularProgress />
            ) : (
              <>
                {uiState === "None" && (
                  <>
                    <Box className={classes.field}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setUiState("Confirmation")}
                      >
                        Zrušit předplatné
                      </Button>
                    </Box>
                    <Box className={classes.field}>
                      <Typography variant="body1">
                        Přístup do aplikace zůstane aktivní až do:{" "}
                        <SubscriptionExpirationDate
                          timestamp={subscription.expiryDate}
                        />
                      </Typography>
                    </Box>
                  </>
                )}
                {uiState === "Success" && (
                  <Alert severity="success">
                    Vaše předplatné bylo zrušeno.
                  </Alert>
                )}
                {uiState === "Failure" && (
                  <Alert severity="error">
                    Při rušení předplatného nastala chyba. Zkuste to prosím
                    později nebo nás kontaktujte.
                  </Alert>
                )}
                <Dialog
                  open={uiState === "Confirmation"}
                  onClose={() => setUiState("None")}
                >
                  <DialogTitle>Opravdu chcete zrušit předplatné?</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Pokud předplatné zrušíte, ztratíte přístup do aplikace ke
                      dni{" "}
                      <SubscriptionExpirationDate
                        timestamp={subscription.expiryDate}
                      />
                      .
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setUiState("None")} color="primary">
                      Zpět
                    </Button>
                    <Button
                      onClick={() => {
                        setUiState("InProgress");
                        cancelSubscription()
                          .then(() => {
                            setUiState("Success");
                          })
                          .catch(() => setUiState("Failure"));
                      }}
                      color="secondary"
                      autoFocus
                    >
                      Ano, zrušit předplatné
                    </Button>
                  </DialogActions>
                </Dialog>
                <Backdrop open={uiState === "InProgress"}>
                  <CircularProgress />
                </Backdrop>
              </>
            )}
            <Box className={classes.field}>
              <RouterLink to="/app/profil">Zpět na můj profil</RouterLink>
            </Box>
          </>
        )}
      </Container>
    </AppChrome>
  );
}
