import firebase from "firebase/app";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

type DocumentData = firebase.firestore.DocumentData;

export function useDebouncedLinker(
  data: DocumentData | undefined,
  onChange: (changeset: DocumentData) => void
) {
  const [changeset, setChangeset] = useState<DocumentData>({});

  // debounced function is only a timer to avoid closures
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeDebounced = useCallback(
    _.debounce((cb) => {
      cb();
    }, 2000),
    [onChange]
  );

  // return a function (changeDebounced.flush) to flush data on unmounting
  useEffect(() => changeDebounced.flush, [changeDebounced]);

  // new data means the snapshot was updated
  // NOTE: may reset the whole form when data was updated on server, to prevent this reset only unmodified fields
  useEffect(() => setChangeset({}), [data]);

  return [
    (field: string) => {
      return {
        value: changeset[field] ?? data?.[field] ?? "",
        onChange: (ev: React.ChangeEvent<HTMLInputElement>) => {
          const updated = { ...changeset, [field]: ev.target.value };
          changeDebounced(() => {
            onChange(updated);
          });
          setChangeset(updated);
        },
      };
    },
  ];
}
