import React, { useState, useMemo } from "react";
import { AppChrome } from "./AppChrome";
import {
  Container,
  Typography,
  makeStyles,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { SearchRounded } from "@material-ui/icons";
import { Root, CategoryLevel, ContentLevel } from "Navigation";
import _ from "lodash";
import WorksheetLink from "controls/WorksheetLink";
import LinkList from "controls/LinkList";

const useStyles = makeStyles(() => ({
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  container: {
    textAlign: "center",
  },
}));

function search(
  phrase: RegExp,
  level: CategoryLevel,
  path: string[]
): [string[], ContentLevel][] {
  return _.sortBy(
    _.flatMap(level.sublevels, (l, key) =>
      l.type === "category"
        ? search(phrase, l, [...path, key])
        : phrase.test(l.label) || phrase.test(_.deburr(l.label))
        ? [[[...path, key], l]]
        : []
    ),
    ([_path, { label }]) => label
  );
}

export function Search() {
  const classes = useStyles();
  const [phrase, setPhrase] = useState("");
  const results = useMemo(() => {
    const p = new RegExp(phrase, /[A-Z]/.test(phrase) ? "" : "i");
    return search(p, Root, [""]);
  }, [phrase]);
  return (
    <AppChrome routingPath={[]}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          Vyhledávání
        </Typography>
        <Input
          autoFocus
          placeholder="Zadejte hledaný výraz..."
          value={phrase}
          onChange={(ev) => setPhrase(ev.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          }
        />
        <LinkList type="content">
          {results.map(([path, r]) => (
            <WorksheetLink
              key={path.join()}
              to={"/app" + path.join("/")}
              worksheet={r}
            />
          ))}
        </LinkList>
      </Container>
    </AppChrome>
  );
}
