import React, { Suspense } from "react";
import { AppChrome } from "./AppChrome";
import { RoutingPath } from "Navigation";

type Props = { content: string; routingPath: RoutingPath[] };

const Presentation = React.lazy(() => import("gamelets/Presentation"));

export function Content(props: Props) {
  return (
    <AppChrome routingPath={props.routingPath}>
      <Suspense fallback={<div>Loading...</div>}>
        <Presentation name={props.content} />
      </Suspense>
    </AppChrome>
  );
}
