import { useEffect, useState } from "react";
import firebase from "firebase/app";

export type AuthState = { initializing: boolean; user: firebase.User | null };

export const useAuth = (): AuthState => {
  const [state, setState] = useState<AuthState>(() => {
    const user = firebase.auth().currentUser;
    return {
      initializing: !user,
      user,
    };
  });

  function onChange(user: firebase.User | null) {
    setState({ initializing: false, user });
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);

    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return state;
};
