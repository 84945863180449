import firebase from "firebase/app";
import { UserProfile } from "@logotron/shared";

const REGION = "europe-west1";
const functions = () => {
  const fs = firebase.app().functions(REGION);
  //fs.useEmulator("localhost", 5000);
  return fs;
};

export async function fetchUserProfile(): Promise<UserProfile> {
  const method = functions().httpsCallable("getUserProfile");
  const result = await method();
  return result.data as UserProfile;
}

export async function updateSub(): Promise<{ uid: string }> {
  const method = functions().httpsCallable("updateSubscription");
  const result = await method();
  return result.data as { uid: string };
}

export async function cancelSubscription(): Promise<void> {
  const method = functions().httpsCallable("cancelSubscription");
  await method();
}
