import React, { useContext, useEffect, useRef, useState } from "react";
import firebase from "firebase/app";
import { useAuth, AuthState } from "./useAuth";
import { fetchUserProfile } from "rest/user";
import { Subscription, UserProfile } from "@logotron/shared";

type DocumentReference = firebase.firestore.DocumentReference;

type Claims = {[key: string]: any};

type UserContextType = AuthState & {
  profile: UserProfile | null;
  claims: Claims | null;
  subscription: Subscription | undefined | "loading";
  userDocRef: DocumentReference | null;
};

const UserContext = React.createContext<UserContextType>({
  initializing: false,
  user: null,
  profile: null,
  claims: null,
  subscription: undefined,
  userDocRef: null,
});

export const UserProvider = (props: { children: React.ReactNode }) => {
  const session = useAuth();
  const docRef = useRef<DocumentReference | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [claims, setClaims] = useState<Claims | null>(null);
  const [subscription, setSubscription] = useState<
    Subscription | undefined | "loading"
  >("loading");

  if (!docRef.current && session.user?.uid) {
    docRef.current = firebase
      .firestore()
      .collection("users")
      .doc(session.user.uid);
  }
  if (docRef.current && !session.user) {
    // logged out
    console.log("User logged out");
    docRef.current = null;
  }

  useEffect(() => {
    if (!docRef.current) return;
    const unsubscribe = docRef.current.onSnapshot((snapshot) => {
      if (!snapshot.exists) {
        // will create profile if does not exist
        fetchUserProfile();
      } else {
        const newProfile = snapshot.data() as UserProfile;
        setProfile(newProfile);
      }
    });
    return () => unsubscribe();
  }, [session.user]);

  useEffect(() => {
    if (!docRef.current) return;
    const unsubscribe = docRef.current
      .collection("claims")
      .doc("current")
      .onSnapshot((snapshot) => {
        console.log("updating token", snapshot.data());
        setClaims(snapshot.data() as Claims);
        session.user?.getIdToken(true).then(() => {
          session.user?.getIdTokenResult().then((r) => {
            console.log(r.claims);
            //setClaims(r.claims)
          });
        });
      });
    return unsubscribe;
  }, [session.user]);

  useEffect(() => {
    if (!docRef.current) return;
    const unsubscribe = docRef.current
      .collection("subscriptions")
      .doc("current")
      .onSnapshot((snapshot) => {
        setSubscription(snapshot.data() as Subscription);
      });
    return unsubscribe;
  }, [session.user]);

  const value = {
    ...session,
    profile,
    claims,
    subscription,
    userDocRef: docRef.current,
  };
  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
