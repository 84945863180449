import React from "react";
import _ from "lodash";
import { CategoryLevel, RoutingPath, ContentLevel } from "Navigation";
import { useRouteMatch, Link } from "react-router-dom";
import { AppChrome } from "./AppChrome";
import { Container, Typography, makeStyles } from "@material-ui/core";
import WorksheetLink from "controls/WorksheetLink";
import LinkList from "controls/LinkList";

const ICONS = require.context("assets/categories");

const useStyles = makeStyles(() => ({
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  container: {
    textAlign: "center",
  },
  hexagon: {
    "-webkit-clip-path":
      "polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%)",
    clipPath: "polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%)",
    width: 210,
    height: 210,
    background:
      "linear-gradient(90deg, rgba(79,196,202,1) 0%, rgba(0,170,171,1) 100%)",
    fontWeight: 700,
    fontSize: "18pt",
    color: "#fff",
    padding: 40,
    textAlign: "center",
  },
  icon: {
    width: 100,
    height: 70,
    display: "block",
    margin: "auto",
  },
  letter: {
    borderRadius: 100,
    width: 80,
    height: 80,
    background:
      "linear-gradient(90deg, rgba(79,196,202,1) 0%, rgba(0,170,171,1) 100%)",
    fontWeight: 700,
    fontSize: "28pt",
    color: "#fff",
    marginRight: 20,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  categoryLink: {
    textDecoration: "none",
    margin: 20,
    transition: "all .2s cubic-bezier(0.3, 0.2, 0.2, 1.4)",
    "&:hover": {
      transform: "scale(1.05)",
      filter: "drop-shadow(0px 2px 7px rgba(0,0,0,0.4))",
    },
  },
  categoryName: {
    marginTop: 5,
  },
}));

type Props = { level: CategoryLevel; routingPath: RoutingPath[] };

export function Category({ level, routingPath: path }: Props) {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const base = url === "/" ? "/" : url + "/";
  const groups = _.chain(level.sublevels)
    .toPairs()
    .groupBy(([_key, { type }]) => type)
    .value();
  return (
    <AppChrome routingPath={path}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          {level.label}
        </Typography>
        {level.subtitle && (
          <Typography variant="h5">{level.subtitle}</Typography>
        )}
        <LinkList type="categories">
          {_.map(
            groups["category"] as [string, CategoryLevel][],
            ([key, value]) => (
              <Link key={key} to={base + key} className={classes.categoryLink}>
                {value.variant === "letter" ? (
                  <div className={classes.letter}>
                    <p>{value.iconText}</p>
                  </div>
                ) : (
                  <div className={classes.hexagon}>
                    {value.icon ? (
                      <img
                        alt={value.label}
                        className={classes.icon}
                        src={ICONS(`./${value.icon}.svg`).default}
                      />
                    ) : (
                      <div className={classes.icon}></div>
                    )}
                    <p className={classes.categoryName}>{value.label}</p>
                  </div>
                )}
              </Link>
            )
          )}
        </LinkList>
        <LinkList type="content">
          {_.map(groups["content"], ([key, value]) => (
            <WorksheetLink
              key={key}
              to={base + key}
              worksheet={value as ContentLevel}
            />
          ))}
        </LinkList>
      </Container>
    </AppChrome>
  );
}
