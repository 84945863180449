import React from "react";
import {
  BrowserRouter as Router,
  Route,
  useRouteMatch,
  useParams,
  Switch,
  Redirect,
} from "react-router-dom";
import { CategoryLevel, Root, RoutingPath } from "./Navigation";
import { Category } from "components/Category";
import { Content } from "components/Content";

import { UserProvider, useUser } from "./auth/UserContext";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";

import firebaseConfig from "./firebaseConfig";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { theme } from "./MaterialUiTheme";
import AuthPage from "./auth/AuthPage";
import { Search } from "components/Search";
import { UserProfilePage } from "components/UserProfilePage";
import { Welcome } from "components/Welcome";
import { PrivacyPolicy } from "components/PrivacyPolicy";
import { Buy } from "components/Buy";
import CookieConsent from "react-cookie-consent";
import { CancelSubscriptionPage } from "components/CancelSubscriptionPage";

const auth = (window as any).firebaseui.auth;
// hack for FirebaseUi.tsx
(window as any).firebase = firebase;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

// This is our firebaseui configuration object
const uiConfig = {
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  credentialHelper: auth.CredentialHelper.NONE, // disable "account chooser"
  tosUrl: "/terms-of-service", // This doesn't exist yet
};

function RoutingTree(props: {
  tree: CategoryLevel;
  routingPath: RoutingPath[];
}) {
  const { url } = useRouteMatch();
  const { id } = useParams<{ id: string | undefined }>();
  const { tree, routingPath } = props;
  const level = id ? tree.sublevels[id] : tree;
  if (!level) {
    // path in URL does not exist in the navigation tree
    return <Redirect to="/app" />;
  }
  const newPath = [...routingPath, { level, url }];
  if (level.type === "content") {
    return <Content content={level.content} routingPath={newPath} />;
  }
  return (
    <Switch>
      <Route path={(url === "/" ? "" : url) + "/:id"}>
        <RoutingTree tree={level} routingPath={newPath} />
      </Route>
      <Route path={url}>
        <Category level={level} routingPath={newPath} />
      </Route>
    </Switch>
  );
}

const Protected = (props: { children: React.ReactNode }) => {
  const { user, initializing } = useUser();
  if (initializing)
    return <div>Prosím čekejte. Načítám informace o uživateli...</div>;
  return user ? (
    <>{props.children}</>
  ) : (
    <Switch>
      <Route>
        <AuthPage uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </Route>
    </Switch>
  );
};

const Subscribers = (props: { children: React.ReactNode }) => {
  const { subscription, claims } = useUser();
  //const [claims, setClaims] = useState<
  //{ [key: string]: any } | undefined | "loading"
  //>("loading");
  //useEffect(() => {
  //if (!auth.user) return;
  //auth.user.getIdTokenResult().then((token) => setClaims(token.claims));
  //}, [auth.user]);
  if (subscription === "loading") return null;
  if (claims?.isAdmin) {
    console.log("User is administrator");
  }
  if (subscription?.expiryDate) {
    console.log(
      "Subscription will expire on " + new Date(subscription.expiryDate)
    );
  }
  if (claims?.isAdmin || (subscription?.expiryDate ?? 0) > new Date().valueOf())
    return <>{props.children}</>;
  return <Redirect to="/app/profil" />;
};

function App() {
  //useEffect(() => startFirebaseUI("#firebaseui"), []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Must be inside ThemeProvider! */}
      <UserProvider>
        <Router>
          <Switch>
            <Route path="/" exact component={Welcome} />
            <Route path="/ochrana-osobnich-udaju" component={PrivacyPolicy} />
            <Route path="/app">
              <Protected>
                <Switch>
                  <Route path="/app/hledej" component={Search} />
                  <Route path="/app/profil" component={UserProfilePage} />
                  <Route path="/app/predplatne" component={Buy} />
                  <Route path="/app/zruseni">
                    <Subscribers>
                      <CancelSubscriptionPage />
                    </Subscribers>
                  </Route>
                  <Route>
                    <Subscribers>
                      <RoutingTree tree={Root} routingPath={[]} />
                    </Subscribers>
                  </Route>
                </Switch>
              </Protected>
            </Route>
            <Redirect to="/" />
          </Switch>
        </Router>
        <CookieConsent
          cookieName="logotron-cookie-consent"
          buttonText="Souhlasím"
          location="bottom"
          expires={999}
        >
          Tento web používá soubory cookie. Dalším procházením tohoto webu
          vyjadřujete souhlas s jejich používáním.
        </CookieConsent>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
