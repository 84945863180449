import React from "react";
import { makeStyles } from "@material-ui/core";
import { clsx } from "clsx";

const useStyles = makeStyles(() => ({
  list: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 880,
    margin: "auto", // center
  },
  categoryList: {
    justifyContent: "center",
  },
  contentList: {
    justifyContent: "left",
  },
}));

type Props = {
  type: "categories" | "content";
  children: React.ReactNode;
};

const LinkList = (props: Props) => {
  const classes = useStyles();
  return (
    <section
      className={clsx(
        classes.list,
        props.type === "content" ? classes.contentList : classes.categoryList
      )}
    >
      {props.children}
    </section>
  );
};

export default LinkList;
