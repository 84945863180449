import React from "react";
import { Link } from "react-router-dom";
import "firebase/auth";
//import { auth } from "firebaseui";
import { useUser } from "./UserContext";
import { makeStyles, Typography, SvgIcon } from "@material-ui/core";
import { ReactComponent as Logo } from "../assets/logotron.svg";
import FirebaseAuth from "./FirebaseUi";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100vh",
    background:
      "linear-gradient(90deg, rgba(79,196,202,1) 0%, rgba(0,170,171,1) 100%)",
    "& .firebaseui-container": {
      backgroundColor: "white",
      padding: 20,
      minWidth: 340,
    },
  },
  header: {
    color: "white",
    margin: 60,
  },
  headerText: {
    textAlign: "center",
  },
  logo: {
    position: "absolute",
    marginLeft: -150,
    height: 110,
    width: "auto",
  },
  title: {
    fontWeight: "bold",
  },
  subtitle: {},
}));

type Props = {
  uiConfig: any;
  firebaseAuth: any;
};

function Auth(props: Props) {
  const { user, initializing } = useUser();
  const classes = useStyles();
  return user || initializing ? null : (
    <div className={classes.container}>
      <header className={classes.header}>
        <Link to="/">
          <SvgIcon
            className={classes.logo}
            component={Logo}
            viewBox="0 0 618 629"
          />
        </Link>
        <div className={classes.headerText}>
          <Typography variant="h2" className={classes.title}>
            LOGOTRON
          </Typography>
          <Typography variant="h5">logopedická terapie on-line</Typography>
        </div>
      </header>
      <FirebaseAuth
        uiConfig={props.uiConfig}
        firebaseAuth={props.firebaseAuth}
      />
    </div>
  );
}

export default Auth;
