import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { ContentLevel } from "../Navigation";

const ICONS = require.context("../assets/categories");

const useStyles = makeStyles(() => ({
  square: {
    width: 114,
    height: 80,
    flex: "0 0 auto", // keep size of icon
    background:
      "linear-gradient(90deg, rgba(79,196,202,1) 0%, rgba(0,170,171,1) 100%)",
    fontWeight: 700,
    fontSize: "18pt",
    color: "#fff",
    marginRight: 20,
    textAlign: "center",
    borderRadius: 7,
    padding: 3,
    display: "flex",
    alignItems: "stretch",
    //transition: 'all .2s ease-in-out',
    transition: "all .2s cubic-bezier(0.3, 0.2, 0.2, 1.4)",
    "& > *": {
      flex: "1 1 auto",
      display: "block",
      borderRadius: 5,
    },
  },
  imageIcon: {
    backgroundColor: "white",
    position: "relative",
    "& img": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 5,
      verticalAlign: "middle",
    },
  },
  contentLink: {
    display: "flex",
    alignItems: "center",
    width: 400,
    fontWeight: 700,
    fontSize: "18pt",
    color: "rgba(0,170,171,1)",
    textDecoration: "none",
    margin: 20,
    textAlign: "left",
    "&:hover > div:first-child": {
      transform: "scale(1.05)",
      boxShadow: "0px 2px 7px 0px rgba(0,0,0,0.4)",
    },
  },
}));

type Props = {
  worksheet: ContentLevel;
  to: string;
};

const WorksheetLink = (props: Props) => {
  const classes = useStyles();
  return (
    <Link to={props.to} className={classes.contentLink}>
      <div className={classes.square}>
        {props.worksheet.contentIcon ? (
          <div className={classes.imageIcon}>
            <img
              alt={props.worksheet.label}
              src={`/gamelets/${props.worksheet.content}/${props.worksheet.contentIcon}`}
            />
          </div>
        ) : props.worksheet.icon ? (
          <img
            alt={props.worksheet.label}
            src={ICONS(`./${props.worksheet.icon}.svg`).default}
          />
        ) : (
          <p>{props.worksheet.iconText || props.worksheet.label}</p>
        )}
      </div>
      <p>{props.worksheet.label}</p>
    </Link>
  );
};

export default WorksheetLink;
