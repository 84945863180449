export type ContentLevel = {
  type: "content";
  label: string;
  icon?: string;
  content: string;
  iconText?: string;
  contentIcon?: string;
};

export type CategoryLevel = {
  type: "category";
  label: string;
  variant?: "letter";
  subtitle?: string;
  icon?: string;
  iconText?: string;
  sublevels: { [key: string]: CategoryLevel | ContentLevel };
};

export type RoutingPath = { url: string; level: CategoryLevel | ContentLevel };

export const Root: CategoryLevel = {
  type: "category",
  label: "Logotron",
  subtitle: "Logopedická terapie on-line",
  sublevels: {
    rec: {
      type: "category",
      label: "Řeč",
      icon: "rec",
      sublevels: {
        vyslovnost: {
          type: "category",
          label: "Výslovnost",
          icon: "vyslovnost",
          sublevels: {
            l: {
              type: "category",
              label: "Hláska L",
              variant: "letter",
              iconText: "L",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba L",
                  content: "L-slovnizasoba",
                  contentIcon: "Luk1.png",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo L",
                  icon: "bila-sesit",
                  content: "L-mnoznecislo",
                  contentIcon: "01.svg",
                },
                pocetslabik: {
                  type: "content",
                  label: "Počet slabik L",
                  icon: "bila-sesit",
                  content: "L-pocetslabik",
                },
                rozpoznani: {
                  type: "content",
                  label: "Rozpoznání hlásky L",
                  icon: "bila-sesit",
                  content: "L-rozpoznanihlasky",
                },
                vety: {
                  type: "content",
                  label: "Jednoduché věty L",
                  icon: "bila-sesit",
                  content: "L-jednoduchevety",
                },
                delsivety: {
                  type: "content",
                  label: "Delší věty L",
                  icon: "bila-sesit",
                  content: "L-delsivety",
                },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků L",
                  icon: "bila-sesit",
                  content: "L-utvorvetu",
                },
                hratky: {
                  type: "content",
                  label: "Hrátky s obrázky L",
                  icon: "bila-sesit",
                  content: "L-hratkysobrazky",
                },
                predlozky: {
                  type: "content",
                  label: "Předložky L",
                  icon: "bila-sesit",
                  content: "L-predlozky",
                },
                logika: {
                  type: "content",
                  label: "Logické myšlení L",
                  icon: "bila-sesit",
                  content: "L-logika",
                },
              },
            },
            r: {
              type: "category",
              label: "Hláska R",
              variant: "letter",
              iconText: "R",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba R",
                  iconText: "R",
                  content: "R-slovnizasoba",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo R",
                  icon: "bila-sesit",
                  content: "R-mnoznecislo",
                },
                pocetslabik: {
                  type: "content",
                  label: "Počet slabik R",
                  icon: "bila-sesit",
                  content: "R-pocetslabik",
                },
                rozpoznani: {
                  type: "content",
                  label: "Rozpoznání hlásky R",
                  icon: "bila-sesit",
                  content: "R-rozpoznanihlasky",
                },
                vety: {
                  type: "content",
                  label: "Jednoduché věty R",
                  icon: "bila-sesit",
                  content: "R-jednoduchevety",
                },
                delsivety: {
                  type: "content",
                  label: "Delší věty R",
                  icon: "bila-sesit",
                  content: "R-delsivety",
                },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků R",
                  icon: "bila-sesit",
                  content: "R-utvorvetu",
                },
                hratky: {
                  type: "content",
                  label: "Hrátky s obrázky R",
                  icon: "bila-sesit",
                  content: "R-hratkysobrazky",
                },
                predlozky: {
                  type: "content",
                  label: "Předložky R",
                  icon: "bila-sesit",
                  content: "R-predlozky",
                },
                logika: {
                  type: "content",
                  label: "Logické myšlení R",
                  icon: "bila-sesit",
                  content: "R-logika",
                },
              },
            },
            rr: {
              type: "category",
              label: "Hláska Ř",
              variant: "letter",
              iconText: "Ř",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba Ř",
                  iconText: "Ř",
                  content: "RR-slovnizasoba",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo Ř",
                  icon: "bila-sesit",
                  content: "RR-mnoznecislo",
                },
                pocetslabik: {
                  type: "content",
                  label: "Počet slabik Ř",
                  icon: "bila-sesit",
                  content: "RR-pocetslabik",
                },
                rozpoznani: {
                  type: "content",
                  label: "Rozpoznání hlásky Ř",
                  icon: "bila-sesit",
                  content: "RR-rozpoznanihlasky",
                },
                vety: {
                  type: "content",
                  label: "Jednoduché věty Ř",
                  icon: "bila-sesit",
                  content: "RR-jednoduchevety",
                },
                delsivety: {
                  type: "content",
                  label: "Delší věty Ř",
                  icon: "bila-sesit",
                  content: "RR-delsivety",
                },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků Ř",
                  icon: "bila-sesit",
                  content: "RR-utvorvetu",
                },
                hratky: {
                  type: "content",
                  label: "Hrátky s obrázky Ř",
                  icon: "bila-sesit",
                  content: "RR-hratkysobrazky",
                },
                predlozky: {
                  type: "content",
                  label: "Předložky Ř",
                  icon: "bila-sesit",
                  content: "RR-predlozky",
                },
                logika: {
                  type: "content",
                  label: "Logické myšlení Ř",
                  icon: "bila-sesit",
                  content: "RR-logika",
                },
              },
            },

            c: {
              type: "category",
              label: "Hláska C",
              variant: "letter",
              iconText: "C",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba C",
                  iconText: "C",
                  content: "C-slovnizasoba",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo C",
                  icon: "bila-sesit",
                  content: "C-mnoznecislo",
                },
                pocetslabik: {
                  type: "content",
                  label: "Počet slabik C",
                  icon: "bila-sesit",
                  content: "C-pocetslabik",
                },
                rozpoznani: {
                  type: "content",
                  label: "Rozpoznání hlásky C",
                  icon: "bila-sesit",
                  content: "C-rozpoznanihlasky",
                },
                vety: {
                  type: "content",
                  label: "Jednoduché věty C",
                  icon: "bila-sesit",
                  content: "C-jednoduchevety",
                },
                delsivety: {
                  type: "content",
                  label: "Delší věty C",
                  icon: "bila-sesit",
                  content: "C-delsivety",
                },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků C",
                  icon: "bila-sesit",
                  content: "C-utvorvetu",
                },
                hratky: {
                  type: "content",
                  label: "Hrátky s obrázky C",
                  icon: "bila-sesit",
                  content: "C-hratkysobrazky",
                },
                predlozky: {
                  type: "content",
                  label: "Předložky C",
                  icon: "bila-sesit",
                  content: "C-predlozky",
                },
                logika: {
                  type: "content",
                  label: "Logické myšlení C",
                  icon: "bila-sesit",
                  content: "C-logika",
                },
              },
            },

            s: {
              type: "category",
              label: "Hláska S",
              variant: "letter",
              iconText: "S",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba S",
                  iconText: "S",
                  content: "S-slovnizasoba",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo S",
                  icon: "bila-sesit",
                  content: "S-mnoznecislo",
                },
                pocetslabik: {
                  type: "content",
                  label: "Počet slabik S",
                  icon: "bila-sesit",
                  content: "S-pocetslabik",
                },
                rozpoznani: {
                  type: "content",
                  label: "Rozpoznání hlásky S",
                  icon: "bila-sesit",
                  content: "S-rozpoznanihlasky",
                },
                vety: {
                  type: "content",
                  label: "Jednoduché věty S",
                  icon: "bila-sesit",
                  content: "S-jednoduchevety",
                },
                delsivety: {
                  type: "content",
                  label: "Delší věty S",
                  icon: "bila-sesit",
                  content: "S-delsivety",
                },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků S",
                  icon: "bila-sesit",
                  content: "S-utvorvetu",
                },
                hratky: {
                  type: "content",
                  label: "Hrátky s obrázky S",
                  icon: "bila-sesit",
                  content: "S-hratkysobrazky",
                },
                predlozky: {
                  type: "content",
                  label: "Předložky S",
                  icon: "bila-sesit",
                  content: "S-predlozky",
                },
                logika: {
                  type: "content",
                  label: "Logické myšlení S",
                  icon: "bila-sesit",
                  content: "S-logika",
                },
              },
            },

            z: {
              type: "category",
              label: "Hláska Z",
              variant: "letter",
              iconText: "Z",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba Z",
                  iconText: "Z",
                  content: "Z-slovnizasoba",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo Z",
                  icon: "bila-sesit",
                  content: "Z-mnoznecislo",
                },
                // pocetslabik: {
                //   type: "content",
                //   label: "Počet slabik Ř",
                //   icon: "bila-sesit",
                //   content: "RR-pocetslabik",
                // },
                // rozpoznani: {
                //   type: "content",
                //   label: "Rozpoznání hlásky Ř",
                //   icon: "bila-sesit",
                //   content: "RR-rozpoznanihlasky",
                // },
                // vety: {
                //   type: "content",
                //    label: "Jednoduché věty Ř",
                //    icon: "bila-sesit",
                //    content: "RR-jednoduchevety",
                // },
                // delsivety: {
                //   type: "content",
                //    label: "Delší věty Ř",
                //    icon: "bila-sesit",
                //    content: "RR-delsivety",
                // },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků Z",
                  icon: "bila-sesit",
                  content: "Z-utvorvetu",
                },
                // hratky: {
                //   type: "content",
                //   label: "Hrátky s obrázky Ř",
                //   icon: "bila-sesit",
                //   content: "RR-hratkysobrazky",
                // },
                // predlozky: {
                //   type: "content",
                //   label: "Předložky Ř",
                //   icon: "bila-sesit",
                //   content: "RR-predlozky",
                // },
                // logika: {
                //   type: "content",
                //   label: "Logické myšlení Ř",
                //   icon: "bila-sesit",
                //   content: "RR-logika",
                // },
              },
            },

            cc: {
              type: "category",
              label: "Hláska Č",
              variant: "letter",
              iconText: "Č",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba Č",
                  iconText: "Č",
                  content: "CC-slovnizasoba",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo Č",
                  icon: "bila-sesit",
                  content: "CC-mnoznecislo",
                },
                pocetslabik: {
                  type: "content",
                  label: "Počet slabik Č",
                  icon: "bila-sesit",
                  content: "CC-pocetslabik",
                },
                rozpoznani: {
                  type: "content",
                  label: "Rozpoznání hlásky Č",
                  icon: "bila-sesit",
                  content: "CC-rozpoznanihlasky",
                },
                vety: {
                  type: "content",
                  label: "Jednoduché věty Č",
                  icon: "bila-sesit",
                  content: "CC-jednoduchevety",
                },
                delsivety: {
                  type: "content",
                  label: "Delší věty Č",
                  icon: "bila-sesit",
                  content: "CC-delsivety",
                },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků Č",
                  icon: "bila-sesit",
                  content: "CC-utvorvetu",
                },
                hratky: {
                  type: "content",
                  label: "Hrátky s obrázky Č",
                  icon: "bila-sesit",
                  content: "CC-hratkysobrazky",
                },
                predlozky: {
                  type: "content",
                  label: "Předložky Č",
                  icon: "bila-sesit",
                  content: "CC-predlozky",
                },
                logika: {
                  type: "content",
                  label: "Logické myšlení Č",
                  icon: "bila-sesit",
                  content: "CC-logika",
                },
              },
            },

            ss: {
              type: "category",
              label: "Hláska Š",
              variant: "letter",
              iconText: "Š",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba Š",
                  iconText: "Š",
                  content: "SS-slovnizasoba",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo Š",
                  icon: "bila-sesit",
                  content: "SS-mnoznecislo",
                },
                // pocetslabik: {
                //   type: "content",
                //   label: "Počet slabik S",
                //   icon: "bila-sesit",
                //   content: "S-pocetslabik",
                // },
                // rozpoznani: {
                //   type: "content",
                //   label: "Rozpoznání hlásky S",
                //   icon: "bila-sesit",
                //   content: "S-rozpoznanihlasky",
                // },
                // vety: {
                //  type: "content",
                //   label: "Jednoduché věty S",
                //   icon: "bila-sesit",
                //   content: "S-jednoduchevety",
                // },
                // delsivety: {
                //   type: "content",
                //   label: "Delší věty S",
                //   icon: "bila-sesit",
                //   content: "S-delsivety",
                // },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků Š",
                  icon: "bila-sesit",
                  content: "SS-utvorvetu",
                },
                // hratky: {
                //   type: "content",
                //   label: "Hrátky s obrázky S",
                //   icon: "bila-sesit",
                //   content: "S-hratkysobrazky",
                // },
                // predlozky: {
                //   type: "content",
                //   label: "Předložky S",
                //   icon: "bila-sesit",
                //   content: "S-predlozky",
                // },
                // logika: {
                //   type: "content",
                //   label: "Logické myšlení S",
                //   icon: "bila-sesit",
                //   content: "S-logika",
                // },
              },
            },

            zz: {
              type: "category",
              label: "Hláska Ž",
              variant: "letter",
              iconText: "Ž",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba Ž",
                  iconText: "Ž",
                  content: "ZZ-slovnizasoba",
                },
                mnoznecislo: {
                  type: "content",
                  label: "Množné číslo Ž",
                  icon: "bila-sesit",
                  content: "ZZ-mnoznecislo",
                },
                // pocetslabik: {
                //   type: "content",
                //   label: "Počet slabik Ř",
                //   icon: "bila-sesit",
                //   content: "RR-pocetslabik",
                // },
                // rozpoznani: {
                //   type: "content",
                //   label: "Rozpoznání hlásky Ř",
                //   icon: "bila-sesit",
                //   content: "RR-rozpoznanihlasky",
                // },
                // vety: {
                //   type: "content",
                //    label: "Jednoduché věty Ř",
                //    icon: "bila-sesit",
                //    content: "RR-jednoduchevety",
                // },
                // delsivety: {
                //   type: "content",
                //    label: "Delší věty Ř",
                //    icon: "bila-sesit",
                //    content: "RR-delsivety",
                // },
                utvorvetu: {
                  type: "content",
                  label: "Utvoř větu z obrázků Ž",
                  icon: "bila-sesit",
                  content: "ZZ-utvorvetu",
                },
                // hratky: {
                //   type: "content",
                //   label: "Hrátky s obrázky Ř",
                //   icon: "bila-sesit",
                //   content: "RR-hratkysobrazky",
                // },
                // predlozky: {
                //   type: "content",
                //   label: "Předložky Ř",
                //   icon: "bila-sesit",
                //   content: "RR-predlozky",
                // },
                // logika: {
                //   type: "content",
                //   label: "Logické myšlení Ř",
                //   icon: "bila-sesit",
                //   content: "RR-logika",
                // },
              },
            },

            k: {
              type: "category",
              label: "Hláska K",
              variant: "letter",
              iconText: "K",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba K",
                  iconText: "K",
                  content: "K-slovnizasoba",
                },
              },
            },

            dtn: {
              type: "category",
              label: "Hláska DTN",
              variant: "letter",
              iconText: "DTN",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba DTN",
                  iconText: "DTN",
                  content: "DTN-slovnizasoba",
                },
              },
            },

            ddttnn: {
              type: "category",
              label: "Hláska ĎŤŇ",
              variant: "letter",
              iconText: "ĎŤŇ",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba ĎŤŇ",
                  iconText: "ĎŤŇ",
                  content: "DDTTNN-slovnizasoba",
                },
              },
            },

            vf: {
              type: "category",
              label: "Hláska VF",
              variant: "letter",
              iconText: "VF",
              sublevels: {
                zasoba: {
                  type: "content",
                  label: "Slovní zásoba VF",
                  iconText: "VF",
                  content: "VF-slovnizasoba",
                },
              },
            },
          },
        },
        zasoba: {
          type: "category",
          label: "Slovní zásoba",
          icon: "slovnizasoba",
          sublevels: {
            kategorie: {
              type: "content",
              label: "Kategorie - sety obrázků",
              icon: "bila-sesit",
              content: "Zasoba-Kategorie",
            },
            doprava: {
              type: "content",
              label: "Doprava",
              icon: "bila-sesit",
              content: "Zasoba-Doprava",
            },
            zelenina: {
              type: "content",
              label: "Zelenina",
              icon: "bila-sesit",
              content: "Zasoba-Zelenina",
            },
            ovoce: {
              type: "content",
              label: "Ovoce",
              icon: "bila-sesit",
              content: "Zasoba-Ovoce",
            },
            naradi: {
              type: "content",
              label: "Nářadí a pomůcky",
              icon: "bila-sesit",
              content: "Zasoba-Naradi",
            },
            zviratadomaci: {
              type: "content",
              label: "Zvířata - domácí",
              icon: "bila-sesit",
              content: "Zasoba-zvirata-domaci",
            },
            zvirataunas: {
              type: "content",
              label: "Zvířata - u nás",
              icon: "bila-sesit",
              content: "Zasoba-zvirata-unas",
            },
            zviratazoo: {
              type: "content",
              label: "Zvířata - zoo",
              icon: "bila-sesit",
              content: "Zasoba-zvirata-zoo",
            },
            obleceni: {
              type: "content",
              label: "Oblečení",
              icon: "bila-sesit",
              content: "Zasoba-obleceni",
            },
            nadobi: {
              type: "content",
              label: "Nádobí",
              icon: "bila-sesit",
              content: "Zasoba-nadobi",
            },
            lidsketelo: {
              type: "content",
              label: "Lidské tělo",
              icon: "bila-sesit",
              content: "Zasoba-lidsketelo",
            },
            nabytek: {
              type: "content",
              label: "Dům",
              icon: "bila-sesit",
              content: "Zasoba-dum",
            },
          },
        },
        gramatika: {
          type: "category",
          label: "Gramatika",
          icon: "gramatika",
          sublevels: {
            predlozky: {
              type: "category",
              label: "Předložky",
              icon: "gramatika-predlozky",
              sublevels: {
                predlozkypes: {
                  type: "content",
                  label: "Předložky pes",
                  icon: "bila-sesit",
                  content: "PredlozkyPostel-Pes",
                },
                predlozkykluk: {
                  type: "content",
                  label: "Předložky kluk",
                  icon: "bila-sesit",
                  content: "PredlozkyKluk",
                },
                predlozkystrom: {
                  type: "content",
                  label: "Předložky strom",
                  icon: "bila-sesit",
                  content: "PredlozkyStrom",
                },
                predlozkyschranka: {
                  type: "content",
                  label: "Předložky schránka",
                  icon: "bila-sesit",
                  content: "PredlozkySchranka",
                },
                predlozkybranka: {
                  type: "content",
                  label: "Předložky branka",
                  icon: "bila-sesit",
                  content: "PredlozkyBranka",
                },
                predlozkyletadlo: {
                  type: "content",
                  label: "Předložky letadlo",
                  icon: "bila-sesit",
                  content: "PredlozkyLetadlo",
                },
              },
            },
            rody: {
              type: "category",
              label: "Rody",
              icon: "rody",
              sublevels: {
                rodyjmen: {
                  type: "content",
                  label: "Rody jmen - ten ta to",
                  icon: "bila-sesit",
                  content: "RodyJmen",
                },
              },
            },
            mnoznecislo: {
              type: "category",
              label: "Množné číslo",
              icon: "mnoznecislo",
              sublevels: {
                mnoznecislozenske: {
                  type: "content",
                  label: "Množné číslo - TA",
                  icon: "bila-sesit",
                  content: "mnoznecislo-zenskyrod",
                },
                mnoznecislomuzske: {
                  type: "content",
                  label: "Množné číslo - TEN",
                  icon: "bila-sesit",
                  content: "mnoznecislo-muzskyrod",
                },
                mnoznecislostredni: {
                  type: "content",
                  label: "Množné číslo - TO",
                  icon: "bila-sesit",
                  content: "mnoznecislo-strednirod",
                },
                mnoznecislopomnozne: {
                  type: "content",
                  label: "Množné číslo - TY",
                  icon: "bila-sesit",
                  content: "mnoznecislo-pomnozne",
                },
              },
            },
            pady: {
              type: "category",
              label: "Pády",
              icon: "pady",
              sublevels: {
                pad1: {
                  type: "content",
                  label: "1.pád - KDO, CO",
                  icon: "bila-sesit",
                  content: "pady1",
                },
                pad2: {
                  type: "content",
                  label: "2.pád - KOHO, ČEHO",
                  icon: "bila-sesit",
                  content: "pady2",
                },
                pad3: {
                  type: "content",
                  label: "3.pád - KOMU, ČEMU",
                  icon: "bila-sesit",
                  content: "pady3",
                },
                pad4: {
                  type: "content",
                  label: "4.pád - KOHO, CO",
                  icon: "bila-sesit",
                  content: "pady4",
                },
                pad5: {
                  type: "content",
                  label: "5.pád - oslovujeme, voláme",
                  icon: "bila-sesit",
                  content: "pady5",
                },
                pad6: {
                  type: "content",
                  label: "6.pád - KOM, ČEM",
                  icon: "bila-sesit",
                  content: "pady6",
                },
                pad7: {
                  type: "content",
                  label: "7.pád - KÝM, ČÍM",
                  icon: "bila-sesit",
                  content: "pady7",
                },
              },
            },
          },
        },
        porozumeni: {
          type: "category",
          label: "Porozumění",
          icon: "porozumeni",
          sublevels: {
            opravchybu: {
              type: "content",
              label: "Oprav chybu",
              icon: "bila-sesit",
              content: "Oprav-chybu",
            },
            hadejholku: {
              type: "content",
              label: "Hádej obrázek holčičky",
              icon: "bila-sesit",
              content: "Hadejobrazekholcicky",
            },
            hadejkluka: {
              type: "content",
              label: "Hádej obrázek kluka",
              icon: "bila-sesit",
              content: "Hadejobrazekkluka",
            },
            poslouchej: {
              type: "content",
              label: "Poslouchej příběh",
              icon: "bila-sesit",
              content: "Poslouchejpribeh",
            },
          },
        },
        vypraveni: {
          type: "category",
          label: "Vyprávění",
          icon: "vypraveni",
          sublevels: {
            tvorbavetbatolata: {
              type: "content",
              label: "Tvorba vět - Batolata",
              icon: "bila-sesit",
              content: "TvorbaVetBatolata",
            },
            tvorbavet2: {
              type: "content",
              label: "Utvoř větu - 2 slova",
              icon: "bila-sesit",
              content: "TvorbaVet2",
            },
            tvorbavet3: {
              type: "content",
              label: "Utvoř větu - 3 slova",
              icon: "bila-sesit",
              content: "TvorbaVet3",
            },
            //popisobrazek: {
            //type: "content",
            //label: "Popiš obrázek",
            //icon: "bila-sesit",
            //content: "PopisObrazek",
            //},
          },
        },
      },
    },
    sluch: {
      type: "category",
      label: "Sluch",
      icon: "sluch",
      // cat hlaskovani
      sublevels: {
        hlaskovani: {
          type: "category",
          label: "Hláskování",
          icon: "sluch-hlaskovani",
          sublevels: {
            prvnihlaska: {
              type: "content",
              label: "První hláska",
              icon: "bila-sesit",
              content: "PrvniHlaska",
            },
            poslednihlaska: {
              type: "content",
              label: "Poslední hláska",
              icon: "bila-sesit",
              content: "PosledniHlaska",
            },
            prostrednihlaska: {
              type: "content",
              label: "Prostřední hláska",
              icon: "bila-sesit",
              content: "ProstredniHlaska",
            },
            sluchrozdil: {
              type: "content",
              label: "Sluchové rozlišování",
              icon: "bila-sesit",
              content: "Sluch-rozdily",
            },
            sluchobrazkovecteni: {
              type: "content",
              label: "Obrázkové čtení - hlásky",
              icon: "bila-sesit",
              content: "Hlasky-ObrazkoveCteni",
            },
          },
        },

        slabikovani: {
          type: "category",
          label: "Slabikování",
          icon: "sluch-slabikovani",
          sublevels: {
            urcipocetslabik: {
              type: "content",
              label: "Urči počet slabik",
              icon: "bila-sesit",
              content: "Slabiky-urcipocetslabik",
            },
            prvnislabika: {
              type: "content",
              label: "První slabika - Spoj slova",
              icon: "bila-sesit",
              content: "Slabiky-spojslova",
            },
            sluchobrazkovectenislabiky: {
              type: "content",
              label: "Obrázkové čtení - slabiky",
              icon: "bila-sesit",
              content: "Slabiky-obrazkoveCteni",
            },
          },
        },
        rytmizace: {
          type: "category",
          label: "Rytmizace",
          icon: "sluch-rytmizace",
          sublevels: {
            rytmusvzorce: {
              type: "content",
              label: "Rytmizace - vzorce",
              icon: "bila-sesit",
              content: "Rytmizace-vzorce",
            },
            rytmusslova: {
              type: "content",
              label: "Rytmizace - slova",
              icon: "bila-sesit",
              content: "Rytmizace-spojslovo",
            },
            rytmusvet: {
              type: "content",
              label: "Rytmizace - věty",
              icon: "bila-sesit",
              content: "Rytmizace-vety",
            },
          },
        },
        rymovani: {
          type: "category",
          label: "Rýmy",
          icon: "rymovani",
          sublevels: {
            rymy: {
              type: "content",
              label: "Rýmy-dvojice",
              icon: "bila-sesit",
              content: "Rymy-dvojice",
            },
            rymyvety: {
              type: "content",
              label: "Rýmované věty",
              icon: "bila-sesit",
              content: "Rymy-vety",
            },
            rymyspoj: {
              type: "content",
              label: "Rýmy-spojovačka",
              icon: "bila-sesit",
              content: "Rymy-spojovacka",
            },
            // rymybasnicky: {
            //   type: "content",
            //   label: "Básničky",
            //   icon: "bila-sesit",
            //   content: "Rymy-basnicky",
            // }
          },
        },
      },
    },
    zrak: {
      type: "category",
      label: "Zrak",
      icon: "zrak",
      sublevels: {
        barvy: {
          type: "category",
          label: "Barvy",
          icon: "zrak-barvy",
          sublevels: {
            barvy: {
              type: "content",
              label: "Barvy",
              icon: "bila-sesit",
              content: "Barvy",
            },
            barvyvyjmenujobrazky: {
              type: "content",
              label: "Vyjmenuj obrázky",
              icon: "bila-sesit",
              content: "Barvy-vyjmenujobrazky",
            },
            barvyurcibarvu: {
              type: "content",
              label: "Urči barvu",
              icon: "bila-sesit",
              content: "Barvy-urcibarvu",
            },
            barvyspoj: {
              type: "content",
              label: "Spoj stejné barvy",
              icon: "bila-sesit",
              content: "Barvy-spojstejne",
            },
          },
        },
        // velikosti: {
        //   type: "category",
        //   label: "Velikosti",
        //   icon: "zrak-velikost",
        //   sublevels: {
        //     dobble: {
        //       type: "content",
        //       label: "Velikosti",
        //       icon: "bila-sesit",
        //       content: "Dobble",
        //     }
        //   }
        // },
        kontury: {
          type: "category",
          label: "Kontury",
          icon: "zrak-kontury",
          sublevels: {
            kontury: {
              type: "content",
              label: "Konturové obrázky",
              icon: "bila-sesit",
              content: "Kontury",
            },
            konturybarevne: {
              type: "content",
              label: "Konturové obrázky - barevné",
              icon: "bila-sesit",
              content: "Kontury-barevne",
            },
            konturypulky: {
              type: "content",
              label: "Spoj poloviny",
              icon: "bila-sesit",
              content: "Kontury-poloviny",
            },
          },
        },
        rozdily: {
          type: "category",
          label: "Rozdíly",
          icon: "zrak-rozdily",
          sublevels: {
            stejneobrazky: {
              type: "content",
              label: "Spoj stejné obrázky",
              icon: "bila-sesit",
              content: "Spojstejneobrazky",
            },
            stejnepismena: {
              type: "content",
              label: "Najdi písmena",
              icon: "bila-sesit",
              content: "Spojstejnepismena",
            },
            vcemselisi: {
              type: "content",
              label: "Vyber stejný",
              icon: "bila-sesit",
              content: "Najdistejny",
            },
            petrozdilu: {
              type: "content",
              label: "Najdi 5 rozdílů",
              icon: "bila-sesit",
              content: "Najdi5rozdilu",
            },
            desetrozdilu: {
              type: "content",
              label: "Najdi 10 rozdílů",
              icon: "bila-sesit",
              content: "Najdi10rozdilu",
            },
            sbez: {
              type: "content",
              label: "Obrázky S a BEZ",
              icon: "bila-sesit",
              content: "ObrazkySaB",
            },
          },
        },
        doplnDilek: {
          type: "category",
          label: "Doplň dílek",
          icon: "zrak-doplndilek",
          sublevels: {
            doplndilek: {
              type: "content",
              label: "Doplň chybějící dílek",
              icon: "bila-sesit",
              content: "Doplndilek",
            },
            doplndilekpuzzle: {
              type: "content",
              label: "Doplň puzzle",
              icon: "bila-sesit",
              content: "Doplndilek-puzzle",
            },
          },
        },
        rady: {
          type: "category",
          label: "Řady",
          icon: "zrak-rady",
          sublevels: {
            radyVelikost: {
              type: "content",
              label: "Řady - velikost",
              icon: "bila-sesit",
              content: "RadyVelikost",
            },
            radybarva: {
              type: "content",
              label: "Řady - barva",
              icon: "bila-sesit",
              content: "RadyBarva",
            },
            radyshoradolu: {
              type: "content",
              label: "Řady - shora dolů",
              icon: "bila-sesit",
              content: "RadyShoraDolu",
            },
            radypravoleve: {
              type: "content",
              label: "Řady - pravolevé",
              icon: "bila-sesit",
              content: "RadyPravoleve",
            },
          },
        },
        stiny: {
          type: "category",
          label: "Stíny",
          icon: "zrak-stiny",
          sublevels: {
            stinykategorie: {
              type: "content",
              label: "Stíny - kategorie",
              icon: "bila-sesit",
              content: "Stiny-kategorie",
            },
            stinyryby: {
              type: "content",
              label: "Stíny - ryby",
              icon: "bila-sesit",
              content: "Stiny-ryby",
            },
            stinyauta: {
              type: "content",
              label: "Stíny - auta",
              icon: "bila-sesit",
              content: "Stiny-auta",
            },
          },
        },
        postreh: {
          type: "category",
          label: "Postřeh",
          icon: "zrak-postreh",
          sublevels: {
            dobble: {
              type: "content",
              label: "Dobble",
              icon: "bila-sesit",
              content: "Dobble",
            },
            najdiobrazek: {
              type: "content",
              label: "Najdi obrázek",
              icon: "bila-sesit",
              content: "Postreh-najdiobrazek",
            },
          },
        },
        bludiste: {
          type: "category",
          label: "Bludiště",
          icon: "bludiste",
          sublevels: {
            bludiste: {
              type: "content",
              label: "Cesty",
              icon: "bila-sesit",
              content: "Bludiste",
            },
          },
        },
      },
    },
    pamet: {
      type: "category",
      label: "Pamět",
      icon: "pamet",
      sublevels: {
        slovesa: {
          type: "content",
          label: "Slovesa",
          icon: "bila-sesit",
          content: "PametSlovesa",
        },
        "3obrazky": {
          type: "content",
          label: "Tři obrázky",
          icon: "bila-sesit",
          content: "Pamet3obrazky",
        },
        "4obrazky": {
          type: "content",
          label: "Čtyři obrázky",
          icon: "bila-sesit",
          content: "Pamet4obrazky",
        },
      },
    },
    mysleni: {
      type: "category",
      label: "Myšlení",
      icon: "mysleni",
      sublevels: {
        logickedvojice: {
          type: "content",
          label: "Logické dvojice",
          icon: "bila-sesit",
          content: "Logickedvojice",
        },
        logickerady: {
          type: "content",
          label: "Logické řady",
          icon: "bila-sesit",
          content: "Logickerady",
        },
        zbydemisto: {
          type: "content",
          label: "Má každý místo?",
          icon: "bila-sesit",
          content: "Logika-zbydemisto",
        },
        pocitani: {
          type: "content",
          label: "Počítání",
          icon: "bila-sesit",
          content: "Logika-pocitani",
        },
        porovnavani: {
          type: "content",
          label: "Porovnávání",
          icon: "bila-sesit",
          content: "Logika-porovnavani",
        },
        logickesouvislosti: {
          type: "content",
          label: "Logické souvislosti",
          icon: "bila-sesit",
          content: "Logika-souvislosti",
        },
      },
    },
    kresleni: {
      type: "category",
      label: "Kreslení",
      icon: "motorika",
      sublevels: {
        kreslenigeometrie: {
          type: "content",
          label: "Geometrické tvary",
          icon: "bila-sesit",
          content: "Kresleni-geometrie",
        },
        kreslenisrdce: {
          type: "content",
          label: "Srdce",
          icon: "bila-sesit",
          content: "Kresleni-srdce",
        },
        kreslenidum: {
          type: "content",
          label: "Dům",
          icon: "bila-sesit",
          content: "Kresleni-dum",
        },
        kreslenislunce: {
          type: "content",
          label: "Slunce",
          icon: "bila-sesit",
          content: "Kresleni-slunce",
        },
        kreslenistrom: {
          type: "content",
          label: "Strom",
          icon: "bila-sesit",
          content: "Kresleni-strom",
        },
        kreslenizizala: {
          type: "content",
          label: "Žížala",
          icon: "bila-sesit",
          content: "Kresleni-zizala",
        },
        kreslenkytka: {
          type: "content",
          label: "Kytička",
          icon: "bila-sesit",
          content: "Kresleni-kytka",
        },
        kresleniauto: {
          type: "content",
          label: "Auto",
          icon: "bila-sesit",
          content: "Kresleni-auto",
        },
        kreslenikocka: {
          type: "content",
          label: "Kočka",
          icon: "bila-sesit",
          content: "Kresleni-kocka",
        },
      },
    },
    cteni: {
      type: "category",
      label: "Čtení",
      icon: "cteni",
      sublevels: {
        ctenihlaskovani: {
          type: "category",
          label: "Hlásky",
          icon: "sluch-hlaskovani",
          sublevels: {
            hlaskyposkladejslovo: {
              type: "content",
              label: "Poskládej slovo - 3 písmena",
              icon: "bila-sesit",
              content: "Hlasky-poskladejslovo3",
            },
            hlaskyposkladejslovo2: {
              type: "content",
              label: "Poskládej slovo - 4 písmena",
              icon: "bila-sesit",
              content: "Hlasky-poskladejslovo4",
            },
            hlaskyspojovanihlasek: {
              type: "content",
              label: "Spoj hlásky ve slovo",
              icon: "bila-sesit",
              content: "Hlasky-spojovanihlasek",
            },
            doplnpismeno3: {
              type: "content",
              label: "Doplň písmeno - 3 písmena",
              icon: "bila-sesit",
              content: "Hlasky-doplnpismeno3",
            },
            doplnpismeno4: {
              type: "content",
              label: "Doplň písmeno - 4 písmena",
              icon: "bila-sesit",
              content: "Hlasky-doplnpismeno4",
            },
          },
        },
        ctenislabikovani: {
          type: "category",
          label: "Slabiky",
          icon: "sluch-slabikovani",
          sublevels: {
            poskladejslabiky2: {
              type: "content",
              label: "Poskládej slabiky - 2 slabiky",
              icon: "bila-sesit",
              content: "Slabiky-poskladejslabiky2",
            },
            poskladejslabiky3: {
              type: "content",
              label: "Poskládej slabiky - 3 slabiky",
              icon: "bila-sesit",
              content: "Slabiky-poskladejslabiky3",
            },
            spojovanislabik: {
              type: "content",
              label: "Spoj slabiky ve slovo",
              icon: "bila-sesit",
              content: "Slabiky-spojovanislabik",
            },
            doplnslabiku2: {
              type: "content",
              label: "Doplň slabiku - 2 slabiky",
              icon: "bila-sesit",
              content: "Slabiky-doplnslabiku2",
            },
            doplnslabiku3: {
              type: "content",
              label: "Doplň slabiku - 3 slabiky",
              icon: "bila-sesit",
              content: "Slabiky-doplnslabiku3",
            },
          },
        },
        cteniabeceda: {
          type: "category",
          label: "Abeceda",
          icon: "abeceda",
          sublevels: {
            abeceda: {
              type: "content",
              label: "Abeceda",
              icon: "bila-sesit",
              content: "Abeceda",
            },
          },
        },
        ctenivety: {
          type: "category",
          label: "Věty",
          icon: "cteni-vety",
          sublevels: {
            vetymama: {
              type: "content",
              label: "Věty o mámě",
              icon: "bila-sesit",
              content: "Cteni-vety-mama",
            },
            vetytata: {
              type: "content",
              label: "Věty o tátovi",
              icon: "bila-sesit",
              content: "Cteni-vety-tata",
            },
            doplnvetu: {
              type: "content",
              label: "Doplň slovo do věty",
              icon: "bila-sesit",
              content: "Cteni-doplnvetu",
            },
            vetyliska: {
              type: "content",
              label: "Věty o lišce",
              icon: "bila-sesit",
              content: "Cteni-vety-liska",
            },
            slabikovanivety: {
              type: "content",
              label: "Slabikování - věty",
              icon: "bila-sesit",
              content: "Slabiky-vety",
            },
          },
        },
      },
    },
  },
};
