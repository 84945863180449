import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Button,
  Paper,
} from "@material-ui/core";
import { clsx } from "clsx";

export type Plan = "MONTH" | "YEAR";

const useStyles = makeStyles((theme) => ({
  plan: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  selected: {
    background: "linear-gradient(0deg, white 0%, rgba(0,170,171,0.5) 100%)",
  },
}));

type PlansProps = {
  value?: Plan | null;
  onChange?: (plan: Plan) => void;
};

export function Plans(props: PlansProps) {
  const classes = useStyles();
  const { onChange } = props;
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Paper
          className={clsx(
            classes.plan,
            props.value === "MONTH" && classes.selected,
            !onChange && classes.selected
          )}
        >
          <Typography variant="h5">Měsíční</Typography>
          <Typography variant="body1">
            Plný přístup do aplikace na jeden měsíc
          </Typography>
          <Typography variant="h5">199 Kč</Typography>
          <Typography variant="body1">Automatické obnovení</Typography>
          {onChange && (
            <Box m={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onChange("MONTH")}
              >
                Zvolit
              </Button>
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper
          className={clsx(
            classes.plan,
            props.value === "YEAR" && classes.selected,
            !onChange && classes.selected
          )}
        >
          <Typography variant="h5">Roční</Typography>
          <Typography variant="body1">
            Plný přístup do aplikace na jeden rok
          </Typography>
          <Typography variant="h5">2250 Kč</Typography>
          <Typography variant="body1">Automatické obnovení</Typography>
          {onChange && (
            <Box m={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onChange("YEAR")}
              >
                Zvolit
              </Button>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
