import React, { useState } from "react";
import "./AppChrome.css";
import { Link } from "react-router-dom";
import { useUser } from "auth/UserContext";
import {
  Toolbar,
  AppBar,
  Typography,
  Button,
  SvgIcon,
  makeStyles,
  Drawer,
} from "@material-ui/core";
import {
  HomeOutlined,
  ArrowForwardRounded,
  KeyboardArrowDownRounded,
  SearchRounded,
} from "@material-ui/icons";
import { ReactComponent as Logo } from "assets/logotron.svg";
import { RoutingPath } from "Navigation";
import { UserMenu } from "controls/UserMenu";

const useStyles = makeStyles(() => ({
  toolbar: {
    height: 55,
    "& a": {
      textDecoration: "none",
      color: "#fff",
      "@media print": {
        color: "#000",
        "& h6": {
          fontSize: "50%",
        },
      },
    },
    "& .nav-icon": {
      "@media print": {
        display: "none",
      },
    },
  },
  logo: {
    position: "absolute",
    top: 0,
    marginLeft: 30,
    height: 110,
    width: "auto",
  },
  placeholder: {
    marginRight: 130,
  },
  breadcrumbs: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& *": {
      margin: "0 3px",
    },
  },
  button: {
    marginLeft: 10,
    color: "white",
    maxWidth: 250,
    "@media print": {
      display: "none",
    },
  },
}));

const UserNav = () => {
  const { user, profile } = useUser();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  if (user) {
    const name =
      profile?.displayName || user.displayName || user.email || "User";
    return (
      <>
        <Button
          className={classes.button}
          startIcon={<KeyboardArrowDownRounded />}
          onClick={() => setOpen(true)}
        >
          <Typography noWrap>{name}</Typography>
        </Button>
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
          <UserMenu />
        </Drawer>
      </>
    );
  }
  return null;
};

type Props = {
  routingPath: RoutingPath[];
  children: React.ReactNode;
};

const renderBreadcrumbs = (routingPath: RoutingPath[]) => {
  return routingPath.map(({ level, url }, idx) => (
    <React.Fragment key={url}>
      {idx > 0 && <ArrowForwardRounded className="nav-icon" />}
      <Link key={url} to={url}>
        {idx === 0 ? (
          <HomeOutlined className="nav-icon" />
        ) : (
          <Typography variant="h6">{level.label}</Typography>
        )}
      </Link>
    </React.Fragment>
  ));
};

export function AppChrome(props: Props) {
  const classes = useStyles();
  return (
    <div className="AppChrome">
      <AppBar position="sticky">
        <Toolbar variant="dense" className={classes.toolbar}>
          <Link to="/app">
            <SvgIcon
              className={classes.logo}
              component={Logo}
              viewBox="0 0 618 629"
            />
          </Link>
          <div className={classes.placeholder}></div>
          <div className={classes.breadcrumbs}>
            {renderBreadcrumbs(props.routingPath)}
          </div>
          <Button
            className={classes.button}
            component={Link}
            to="/app/hledej"
            startIcon={<SearchRounded />}
          >
            Hledej
          </Button>
          <UserNav />
        </Toolbar>
      </AppBar>
      <main className="AppChrome-main">{props.children}</main>
    </div>
  );
}
